
.address-content {
  display: flex;
  margin-top: 10px;
  margin-bottom: 10px;
  padding-left: 2rem;
  
  p {
    margin: 0;
  }

  .address-content-column {
    display: flex;
    flex-direction: column;
    gap: 16px;
    width: 50%;
  }
}
  
  