.main {
  background: #171717; 
}

.cards-container {
  background: #e5e5e5;
  // background: #f2f2f2;
  border-top-left-radius: 40px;
}

.edit-cart-info-btn {
  border: none;
  background: none;
  padding: 0;
}

.divider {
  border: 1px solid rgba(226, 232, 240, 1);
  background-color: #e2e8f0;
  width: 100%;
  height: 1px;
  margin-top: 23px;
  margin-bottom: 23px;

  &.hidden {
    display: none;
  }
}

@media screen and (max-width: 575px) {
  .main {
    background: #e5e5e5;
    // background: #f2f2f2;
  }
  
  .cards-container {
    margin-bottom: 20px;
    border-top-left-radius: 0px;
  }
}

.scrollable-cards-container {
  overflow-y: scroll;
  overflow-x: hidden;
  padding-bottom: 2rem;
  height: calc(100vh + 20rem);
  display: flex;
  flex-direction: column;
  row-gap: 20px;
}

@media screen and (max-width: 575px) {
  .scrollable-cards-container {
    overflow: hidden;
    height: 100%;
  }
}

// .sticky-div {
//   background-color: #373c46;
//   border-bottom-left-radius: 20px;
//   border-bottom-right-radius: 20px;
//   min-height: 100px;
//   position: sticky;
//   top: 0;
//   z-index: 1000;
// }