.google-map-wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;

  .google-map-content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;

    .google-map-directions {
      display: flex;
      margin-bottom: 1rem;
      align-items: center;
      position: relative;
      overflow: hidden;
      margin-top: 16px;
      width: 100%;
      height: auto;
      font-size: 12px;
      line-height: 16px;
    }
  }
  
  .google-map-copy-address {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    align-items: center;
    width: 100%;
    margin-top: 0.5rem;

    .google-map-copy-container {
      display: flex;
      font-family: Inter, sans-serif;
      flex: 1;
      cursor: pointer;
      display: flex;
      align-items: center;
      gap: 8px;
      font-size: 16px;

      .google-map-copy-icon {
        width: 20px;
        height: 20px;
      }

      .google-map-copy-text {
        font-family: 'Inter', sans-serif;
        font-size: 16px;
        font-weight: 500;
      }
    }
  }
}
  