input:focus {
  box-shadow: none;
}

.autocomplete-container {
  position: relative;
  width: 100%;
}

.map-icon {
  position: absolute;
  left: 8px;
  top: 50%;
  transform: translateY(-50%);
  z-index: 1;
  width: 18px;
  height: 18px;
}

.autocomplete-container .react-select__control {
  padding-left: 40px;
}

