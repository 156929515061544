.sidebar-mask {
  position: fixed;
  height: 100vh;
  width: 100%;
  left: 0;
  top: 0;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  z-index: 1101;
  background: rgba(10, 10, 10, 0.7);
  transition: all 0.3s;
  
  &.sidebar-mask-active {
    background: transparent;
  }
}

.sidebar {
  display: flex;
  flex-direction: column;
  position: relative;
  height: 100%;
  width: 90%;
  border: 0;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  background-color: #ffffff;
  color: #333333;
  transition: transform 0.3s;
  pointer-events: auto;
  
  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1.125rem;
    background-color: #ffffff;
    color: #333333;
    
    .title {
      font-weight: 600;
      font-size: 1.25rem;
    }
    
    .close-button {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 1.75rem;
      height: 1.75rem;
      border: none;
      border-radius: 50%;
      background: transparent;
      color: #555555;
      transition: all 0.2s ease-in-out;
      cursor: pointer;

      &:hover {
        background: rgba(0, 0, 0, 0.03);
        color: #333333;
      }

      .ripple {
        display: block;
        position: absolute;
        background: rgba(255, 255, 255, 0.5);
        border-radius: 50%;
        pointer-events: none;
        transform: scale(0);
      }
    }
  }
  
  .scroll-content {
    height: 100%;
    width: 100%;
    overflow-y: auto;
    flex-grow: 1;
    color: black;
  }
  
  .content {
    padding: 1.125rem 1.125rem 0;
  }
  
  .details-container {
    display: flex;
    align-items: center;
  }
  
  .order-details-span {
    font-family: "Inter", sans-serif;
    font-size: 24px;
    font-weight: 600;
    color: #101010;
  }
  
  .accessible.hidden-focusable {
    display: none;
  }
  
  .venue-name-span, .package-section-span, .recipient-span  {
    font-family: "Inter", sans-serif;
    font-size: 20px;
    font-weight: 400;
    color: #101010;
  }

  .venue-address-span, .venue-date-span, .package-start-date-span, 
  .recipient-email-span, .order-details-po-span, .invoice-id-span, .attention-span {
    font-family: "Inter", sans-serif;
    font-size: 14px;
    font-weight: 400;
    color: #101010;
  }
  
  .show-me-span {
    font-family: "Inter", sans-serif;
    font-size: 14px;
    font-weight: 500;
    color: #101010;
  }
}

.sidebar-container {
  &.open {
    transform: translateX(0);
  }
}

.sidebar-content {
  .back-button {
    display: flex;
    align-items: center;
    cursor: pointer;
    
    img {
      margin-right: 0.5rem;
    }
  }
  
  .sidebar-items {
    .event-container {
      .event-span {
        margin-bottom: 0;
      }
    }
    
    .dates-container {
      margin-top: 0.25rem;
    }
    
    .d-flex {
      display: flex;
    }
    
    .font-sm {
      font-size: 0.875rem;
      
      .order-span {
        margin: 0;
      }
      
      .invoice-span {
        margin: 0;
      }
    }
  }
}

.thin-border {
  box-shadow: 0 0 0 0.1px #555555;
}
