.responsive-container {
    height: 30vh;
}

.outline-button {
    color: #f7fafc;
    background-color: transparent;
    border: 1px solid #f7fafc;
    padding: 0.375rem 0.75rem;
    text-align: center;
    vertical-align: middle;
    cursor: pointer;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    border-radius: 5px;
}
  
.outline-button:hover,
.outline-button:focus,
.outline-button:active {
    color: #f7fafc;
    background-color: transparent;
    border-color: #f7fafc;
    outline: none;
    box-shadow: none;
}

.got-question-span {
    font-family: "Inter", sans-serif;
    font-size: 14px;
    font-weight: 400;
}

.outline-button img {
    margin-right: 0.5rem;
}

.getInTouch-span {
    font-family: "Inter", sans-serif;
    font-size: 14px;
    font-weight: 500;
    color: #ffffff;
}

.rights-text {
    font-family: "Inter", sans-serif;
    font-size: 12px;
    font-weight: 500;
    color: #71717A;
}

.save-btn {
    background-color: #18181b;
    color: #fff;
    font-size: 14px;
    font-weight: 500;
    font-family: 'Inter', sans-serif;
    border-radius: 10px;
    
    &:disabled {
      background-color: #5f6778;
      color: white;
      cursor: not-allowed;
    }
}

.tickets-details-span {
    font-size: 20px !important;
    font-weight: 600;
    font-family: 'Inter', sans-serif;
}

@media (min-width: 575px) {
    .tickets-details-span {
        font-size: 24px !important;
    }
}



  