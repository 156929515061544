.outer-card-container {
  display: flex;
  flex-direction: column;

  .card-date {
    font-weight: 500;
    font-size: 32px;
    line-height: 41px;

    color: #000000;
  }
}
