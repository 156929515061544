.edit-cart-info-btn {
  border: none;
  background: none;
  padding: 0;
}

.active-filter {
  background: #172b4d !important;
  color: white !important;
}

.test {
  animation: my 1s ease-in-out infinite alternate;
}

@keyframes my {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(1.5);
  }
}

.dot {
  height: 15px;
  width: 15px;
  background-color: #172b4d;
  border-radius: 50%;
  display: inline-block;
}

.sec {
  position: relative;
  right: 12px;
  top: -22px;
}

.counter.counter-lg {
  top: -2px !important;
  color: #fb6340;
}


