.card-filters-container {
  display: flex;
  flex-wrap: wrap;
}

.filter-btn {
  border-radius: 50px;
  border: 1px solid #DCDEE1;
  background: transparent;
  padding: 15px 20px;
  font-family: "Inter", sans-serif;
  font-weight: 500;
  font-size: 15px;
  line-height: 19px;
  color: #18181B;
  flex: none;
  order: 0;
  flex-grow: 0;
  margin: 0px 8px 8px 0px;
  display: block;
  position: relative;

  &.active-filter {
    background: #171717 !important;
    border-radius: 50px;
    color: #FAFAFA !important;
  }

  &.missing-info-sign {
    border: 1px solid #D13053;
    color: #101010;

    &::before {
      content: url('../../assets/img/icons/common/triangle-black.svg');
      margin-right: 8px;
      display: inline-block;
      vertical-align: middle;
    }

    &.active-filter {
      background: #D13053 !important;
      color: white !important;
      border: none;
      
      &::before {
        content: url('../../assets/img/icons/common/triangle-white.svg');
      }
    }
  }
}

@media (max-width: 500px) {
  .card-filters-container {
    display: flex;
    overflow-y: auto;
    flex-wrap: nowrap;
  }

  .filter-btn {
    width: max-content;
  }
}
