.action-details-section {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  align-items: center;
  cursor: pointer;

  .action-details-content {
    font-family: Inter, sans-serif;
    flex: 1;
    display: flex;
    align-items: center;
    gap: 8px;

    .action-details-icon {
      width: 24px;
      height: 24px;
    }
    
    .action-details-text {
      font-size: 16px;
      font-weight: 500;
      font-family: 'Inter', sans-serif;
    }
  }

  .action-details-arrow-icon {
    width: 12px;
    height: 12px;
  }
}