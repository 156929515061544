.tab-left {
  font-size: 12px;
  line-height: 12px;
  font-weight: 300;
  border: 1px solid #dfe5ec;
  border-radius: 5px 0 0 5px;
  background: white;
  padding: 10px;
  flex: 1 0 0;
  cursor: pointer;
  text-align: center;
  outline: none;
}

.tab-right {
  font-size: 12px;
  line-height: 12px;
  font-weight: 300;
  border-top: 1px solid #dfe5ec;
  border-right: 1px solid #dfe5ec;
  border-bottom: 1px solid #dfe5ec;
  border-left: none;

  border-radius: 0 5px 5px 0;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  background: white;
  padding: 10px;
  flex: 1 0 0;
  cursor: pointer;
  text-align: center;
  outline: none;
  position: relative;
}

.is-active-tabs-btn-left {
  background: #1b204c;
  color: white;
  border: none;
}

.is-active-tabs-btn-right {
  background: #1b204c;
  color: white;
  border: none;
}

// .edit-cart-info-btn {
//   border: none;
//   background: none;
//   padding: 0;
// }

// .active-filter {
//   background: #172b4d !important;
//   color: white !important;
// }
