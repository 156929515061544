.menu-container {
  background: #171717;
  border-bottom-left-radius: 44px;
  border-bottom-right-radius: 44px;
  // margin-bottom: 16px;
  min-width: 375px;
  max-width: 600px;
  padding-left: 1rem;
  padding-right: 1rem;
  padding-bottom: 1rem;
  padding-top: 0;
  
  
  @media screen and (min-width: 750px) {
    padding-left: 2rem;
    padding-right: 2rem;
    padding-bottom: 0;
    padding-top: 0;
    min-width: 450px;
    .logo-img-container {
      margin-bottom: 2rem;
    }
    .logo-img {
      width: 144px;
      height: 112px;
    }
  }

  .default-font {
    color: #ffffff;

    .font-lg {
      font-weight: 500;
      font-size: 32px;
      line-height: 41px;
    }

    .font-sm {
      font-weight: 400;
      font-size: 15px;
      line-height: 19px;
    }

    .font-md {
      font-weight: 400;
      font-size: 20px;
      line-height: 26px;
    }
  }

  .line {
    border-top: 1px solid #a6a1c8;
  }
}

.logo-img-container {
  display: flex;
  justify-content: center;
  margin-bottom: 1rem;
}

.logo-img {
  width: 144px;
  height: 112px;
}

.logo-img-mobile {
  width: 278px;
  height: 54px;
}

.dates-container {
  padding-top: 3rem;
  padding-left: 2rem;
}

.dates-span {
  font-family: "Inter", sans-serif;
  font-size: 18px;
  font-weight: 600;
}

.event-container {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 2rem;
  padding-right: 1rem;
}

.event-span {
  font-family: "Inter", sans-serif;
  font-size: 30px;
  font-weight: 600;
  line-height: 36px;
  letter-spacing: -0.0075em;
}

.address-container {
  margin-right: 1rem;
  display: flex;
  justify-content: center;
}

.address-span {
  font-family: "Inter", sans-serif;
  font-size: 14px;
  font-weight: 400;
  padding-left: 2rem;
  color: #FFFFFF;
}

.get-tickets-container {
  background: #373C46;
  border-bottom-left-radius: 16px;
  border-bottom-right-radius: 16px;
}

.get-ticket-img-container {
  display: flex;
}

.get-ticket-img {
  width: 126px;
  height: 56px;
  cursor: pointer;
  margin-right: 10px;
}

.order-details-container {
  font-family: "Inter", sans-serif;
  padding-top: 1rem;
  padding-left: 6rem;
}

.order-details-mobile-container {
  font-family: "Inter", sans-serif;
  font-size: 14px;
  font-weight: 500;
}

.picker-icon {
  margin-left: 2rem;
  width: 40px;
  height: 40px;
}

.order-details-span {
  font-family: "Inter", sans-serif;
  font-size: 20px;
  font-weight: 600;
  padding-left: 1.5rem;
}

.section-span-container-mobile {
  margin-bottom: 0.2rem;
}

.section-span {
  font-family: "Inter", sans-serif;
  font-size: 18px;
  font-weight: 600;
}

.section-span-mobile {
  font-family: "Inter", sans-serif;
  font-size: 14px;
  font-weight: 600;
}

.event-dates {
  font-family: "Inter", sans-serif;
  font-size: 14px;
  font-weight: 400;
}

.event-dates-mobile {
  font-family: "Inter", sans-serif;
  font-size: 14px;
  font-weight: 600;
}

.recipient-span {
  font-family: "Inter", sans-serif;
  font-size: 18px;
  font-weight: 600;
}

.recipient-span-mobile {
  font-family: "Inter", sans-serif;
  font-size: 14px;
  font-weight: 600;
}

.recipient-email-span {
  font-family: "Inter", sans-serif;
  font-size: 14px;
  font-weight: 400;
}

.order-details-open-container {
  padding-left: 2rem;
}

.order-span {
  font-family: "Inter", sans-serif;
  font-size: 14px;
  font-weight: 400;
}

.invoice-span {
  font-family: "Inter", sans-serif;
  font-size: 14px;
  font-weight: 400;
}
