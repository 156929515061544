.anticon-down {
    display: flex !important;
}

.ant-input:focus {
    color: #8898aa;
    background-color: #fff;
    border-color: #5e72e4;
    outline: 0;
    box-shadow: 0 3px 9px rgba(50, 50, 9, 0), 3px 4px 8px rgba(94, 114, 228, 0.1) !important;
}