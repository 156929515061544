.informational-container {
    background: #171717;
    border-bottom-left-radius: 32px;
    border-bottom-right-radius: 32px;
    max-height: 84px;
    position: fixed !important;
    top: 0 !important;
    left: 0;
    right: 0;
    z-index: 1080;
}

.sticky-card-img {
    width: 44px;
    height: 56px;
    object-fit: cover;
    border-radius: 4px;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0) 53.33%, #000000 100%);
    opacity: 0.8px;
    margin-left: 10px;
}

.section-info-p-mobile {
    font-family: "Inter", sans-serif;
    font-size: 14px;
    font-weight: 600;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 150px;
}

.event-dates-info-mobile {
    font-family: "Inter", sans-serif;
    font-size: 12px;
    font-weight: 500;
}

.get-tickets-info-container {
    display: flex;
    justify-content: center;
    align-items: center;
}

.get-tickets-img {
    width: 126px;
    height: 56px;
    cursor: pointer;
  }
