.inputs-container {
    display: flex;
    flex-direction: column;
    gap: 16px;
  
    .section-title {
      font-size: 20px;
      font-weight: 400;
      font-family: 'Inter', sans-serif;
      margin: 0;
    }
  
    .required-note {
      color: #D13053;
      margin: 0;
    }
  
    .input-container {
      display: flex;
      flex-direction: column;
      font-size: 14px;
      font-weight: 500;
      font-family: 'Inter', sans-serif;
  
      .label-invalid {
        color: #D13053;
      }
  
      .input-box {
        position: relative;
  
        .input-invalid {
          border-color: #D13053;
        }
  
        input, textarea {
          width: 100%;
          border: 1px solid rgba(228, 228, 231, 1);
          border-radius: 6px;
          padding: 8px 12px;
          margin-top: 8px;
          font-size: 14px;
          font-weight: 400;
          font-family: 'Inter', sans-serif;
          color: #09090B;
        }
        &::placeholder {
          color: #71717A;
        }
      }
    }
  }
  
  .actions-container {
    display: flex;
    justify-content: space-between;
    margin-top: 16px;
  
    .save-btn {
      background-color: #18181b;
      color: #fff;
      border-radius: 10px;
      font-size: 14px;
      font-weight: 500;
      font-family: 'Inter', sans-serif;
      
      &:disabled {
        background-color: #5f6778;
        color: white;
        cursor: not-allowed;
      }
    }
  }
  
  .vegas-limo-transfer-card-content {
    display: flex;
    margin-top: 10px;
    margin-bottom: 10px;
    padding-left: 2rem;
    
    p {
      margin: 0;
    }
  
    .card-content-column {
      display: flex;
      flex-direction: column;
      gap: 16px;
      width: 50%;
    }
  }
  
  .required-note {
    color: #D13053;
    margin: 0;
  }
    
    