.stage-one-card-container {
  display: flex;
  flex-direction: row;
}

.stage-one-card-icon {
  margin-top: 2.5rem;
  margin-right: 1rem;
}

.stage-one-card-content {
  display: flex;
  width: 100%;
  flex-direction: column;
  color: #101010;
  margin-top: 2rem;
}

.stage-one-card-date {
  align-self: start;
  margin-left: 7rem;
  font-size: 20px;
  font-weight: 600;
  font-family: "Inter", sans-serif;
}

.stage-one-card-header {
  justify-content: space-between;
  border-radius: 20px;
  background-color: #fff;
  display: flex;
  margin-top: 10px;
  width: 100%;
  flex-direction: column;
  font-size: 14px;
  font-weight: 400;
  padding: 24px;
}

.stage-one-card-header-icon {
  display: flex;
  justify-content: center;
  position: relative;
  top: -4.5rem;
  object-position: center;
  align-self: start;
}

.svg-main {
  position: absolute;
  width: 4.5rem;
  height: 4.5rem;
  margin-top: -10px;
  margin-left: 40px;
}

.svg-left {
  width: 4.5rem;
  height: 4.5rem;
}

.missing-info {
  min-width: 120px;
  margin-left: 5px;
  justify-content: center;
  border-radius: 6px;
  background-color: #d13053;
  display: flex;
  gap: 4px;
  font-size: 14px;
  color: #fafafa;
  font-weight: 500;
  text-align: right;
  padding: 4px 8px;
}

.info-accepted {
  min-width: 120px;
  margin-left: 5px;
  justify-content: center;
  border-radius: 6px;
  background-color: #2dce89;
  display: flex;
  gap: 4px;
  font-size: 14px;
  color: #fafafa;
  font-weight: 500;
  text-align: right;
  padding: 4px 8px;
}

@media screen and (min-width: 1200px) {
  .stage-one-card-header-icon {
   display: none;
  }
  .stage-one-card-date {
    margin-left: 1rem;
  }
  .stage-one-card-icon {
    margin-left: 1rem;
  }
}

@media screen and (max-width: 1199px) {
  .stage-one-card-icon {
    display: none;
  }
}

.stage-one-card-details {
  width: 100%;
  align-self: start;
  font-size: 24px;
  font-weight: 600;
  font-family: "Inter", sans-serif;
}

.stage-one-card-title-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
}

.stage-one-card-time-container {
  font-size: 12px;
  font-weight: 500;
  font-family: "Inter", sans-serif;
  background-color: #fafafa;
  border-radius: 3px;
}

.stage-one-card-time-span {
  font-size: 22px;
  font-weight: 600;
  padding: 2px 4px;
  white-space: nowrap;
}

.missing-info-text {
  font-size: 14px;
  font-weight: 400;
  font-family: "Inter", sans-serif;
  color: #101010;
  margin: 0;
}

.accomodation-guarantee-text {
  margin: 0;
  font-weight: 400;
}

.decline-service-unpublished {
  margin: 0;
  font-weight: 600;
  padding-top: 2rem;
  padding-left: 1rem;
}

.phone-link {
  color: #3d6fff;
  text-decoration: underline;
  font-size: 14px;
  line-height: 24px;
  font-family: 'Inter', sans-serif;
  cursor: pointer;
}