.provide-missing-info-label-container {
  .exclamation-circle::before {
    width: 22px;
    height: 22px;
    border: 1px solid red;
    margin-right: 10px;
    border-radius: 50%;
    content: "!";
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 18px;
    color: red;
  }

  .frame {
    background: #fff9f8;
    border: 1px solid red;
    border-radius: 4px;
    width: fit-content;
    padding: 3px;
    margin-right: 10px;

    .provide-missing-info-label {
      font-style: normal;
      font-weight: 900;
      font-size: 12px;
      line-height: 12px;
      text-align: center;
      text-transform: uppercase;
      color: #6c6b5d;
    }
  }

  .info-note {
    color: red;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 14px;
  }
}
