.label-container {
  .exclamation-circle::before {
    width: 22px;
    height: 22px;
    border: 2px solid #D13053;
    margin-right: 10px;
    border-radius: 50%;
    content: "!";
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 18px;
    color: #D13053;
  }

  .missing-info-label {
    font-weight: 500;
    font-size: 15px;
    line-height: 24px;
    // border: 2px solid #D13053;
    color: #D13053;
  }

  .info-note {
    color: #fb6340;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 14px;
    margin-left: 0.5rem;
  }
}
