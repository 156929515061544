.reservation-guaranteed-container {
    width: 100%;
    border-radius: 10px;
}

.reservation-guaranteed-unpublished {
    align-self: center;
    display: flex;
    // margin-top: 24px;
    width: 100%;
    flex-direction: column;
    font-weight: 500;
}

.reservation-guaranteed-unpublished-content {
    justify-content: center;
    border-radius: 8px;
    background-color: #fafafa;
    display: flex;
    flex-direction: column;
    font-weight: 400;
    text-align: center;
    padding: 16px 0;
    // padding: 32px 16px;
}
  
.reservation-guaranteed-unpublished-icon {
    aspect-ratio: 1;
    object-fit: auto;
    object-position: center;
    width: 64px;
    align-self: center;
}
  
.reservation-guaranteed-unpublished-text {
    font-weight: 400;
    font-size: 14px;
    font-family: Inter, sans-serif;
    margin-top: 16px;
}