.hotel-check-inout {
  display: flex;
  gap: 8px;
  
  .hotel-check-icon {
    width: 1.5rem;
    height: 1.5rem;
  }

  .hotel-check-details {
    display: flex;
    width: 50%;
    align-items: start;
    flex-direction: column;
    gap: 8px;
    
    .hotel-check-title {
      font-size: 16px;
      font-weight: 500;
      font-family: 'Inter', sans-serif;
    }

    .hotel-check-date {
      font-size: 14px;
      font-weight: 400;
      font-family: 'Inter', sans-serif;
      margin-top: 4px;
      color: #101010;
    }
    
    .hotel-check-time {
      font-size: 14px;
      font-weight: 400;
      font-family: 'Inter', sans-serif;
      margin-top: 4px;
      color: #5F6778;
    }
  }
}

.hotel-card-content {
  display: flex;
  margin-top: 10px;
  margin-bottom: 10px;
  padding-left: 2rem;
  
  p {
    margin: 0;
  }

  .card-content-column {
    display: flex;
    flex-direction: column;
    gap: 16px;
    width: 50%;
  }
}

.inputs-container {
  display: flex;
  flex-direction: column;
  gap: 16px;

  .section-title {
    font-size: 20px;
    font-weight: 400;
    font-family: 'Inter', sans-serif;
    margin: 0;
  }

  .required-note {
    color: #D13053;
    margin: 0;
  }
  
  .input-containers {
    display: flex;
    width: 100%;
    gap: 16px;
  }

  .input-container {
    display: flex;
    flex-direction: row;
    width: 100%;
    font-size: 14px;
    font-weight: 500;
    font-family: 'Inter', sans-serif;

    .label-invalid {
      color: #D13053;
    }

    .input-box {
      position: relative;

      .input-invalid {
        border-color: #D13053;
      }

      input, textarea {
        width: 100%;
        border: 1px solid rgba(228, 228, 231, 1);
        border-radius: 6px;
        padding: 8px 12px;
        margin-top: 8px;
        font-size: 14px;
        font-weight: 400;
        color: #09090B;
      }
      &::placeholder {
        color: #71717A;
      }
    }
  }
  
  .input-container-names {
    display: flex;
    flex-direction: row;
  }
}

@media (max-width: 768px) {
  .input-containers {
    flex-direction: column;
  }
}

@media (min-width: 768px) {
  .hotel-check-title {
    font-size: 20px !important;
    font-weight: 400;
    font-family: 'Inter', sans-serif;
  }
}

.bed-request {
  .bed-options {
    display: flex;
    gap: 16px;

    .bed-option {
      display: flex;
      align-items: center;
      gap: 4px;
      border: 1px solid rgba(228, 228, 231, 1);
      border-radius: 6px;
      padding: 8px 12px;
      background-color: #f4f4f5;
      cursor: pointer;

      &.active {
        border-color: #18181b;
        background-color: #18181b;
        color: #fff;

        img {
          filter: brightness(0) invert(1);
        }
      }
    }
  }

  .bed-request-label {
    font-size: 20px;
    font-weight: 400;
    font-family: 'Inter', sans-serif;
  }  
    
  .bed-request-note {
    margin: 0;
    font-size: 14px;
    font-weight: 400;
    font-family: 'Inter', sans-serif;
  }
}

.actions-container {
  display: flex;
  justify-content: space-between;
  margin-top: 16px;

  .save-btn {
    background-color: #18181b;
    color: #fff;
    font-size: 14px;
    font-weight: 500;
    font-family: 'Inter', sans-serif;
    border-radius: 10px;
    
    &:disabled {
      background-color: #5f6778;
      color: white;
      cursor: not-allowed;
    }
  }
}

.celebration-title {
  font-size: 16px;
  font-weight: 500;
  font-family: 'Inter', sans-serif;
  color: #101010;
}

