button {
  font-weight: 500;
  font-size: 15px;
  line-height: 19px;

  color: #000000;
}

.is-active-tabs-btn-left {
  color: white;
  border: none;
  background: #101010;
}

.is-active-tabs-btn-right {
  color: white;
  border: none;
  background: #101010;
}
